import React from "react";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Media } from "@ryerson/frontend.layout";
import { ContentSection } from "@ryerson/frontend.layout";
import { Grid, Column, Row } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { ButtonLink } from "@ryerson/frontend.navigation";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";

export type OurHistoryContent = {
	title1: string;
	title2: string;
	years: string;
	subTitle: string;
	superDescription: string;
	description: string;
	videoOverlay: string;
	videoThumbnail: string;
	videoId?: string;
	actionUrl?: string;
	actionLabel: string | string[];
};

export type OurHistoryProps = {
	content: OurHistoryContent;
};

const SubtitleTextContainer = styled.div`
	display: inline-block;
	white-space: pre-line;
	vertical-align: middle;
	margin-right: 20px;
`;

const SecondarySubtitleTextContainer = styled.div`
	display: inline-block;
	width: 60%;
	vertical-align: middle;
`;

const MobileSpacer = styled.div`
	height: 21px;
	width: 100%;
`;

const TitleText = styled.div`
	position: absolute;
	left: 0px;
	width: 100%;
	white-space: nowrap;
	max-width: 100%;
`;

const SubtitleText = styled.div`
	position: absolute;
	left: 0;
	width: 50%;
	max-width: 50%;
	white-space: pre-line;
	margin-top: 30%;
`;

const TextContainer = styled.div`
	margin-left: 120px;
	width: 62%;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (min-width: ${theme.breakpoints.lg}) and (max-width: ${theme
					.breakpoints.xl}) {
				margin-left: 0px;
			}
		`;
	}}
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 140px;
`;

const SuperDescriptionTextContainer = styled.div`
	margin-bottom: 44px;
`;

const DescriptionTextContainer = styled.div`
	margin-bottom: 55px;
`;

const OurHistory: React.FC<OurHistoryProps> = ({ content }) => {
	const { theme } = useTheme();

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ParallaxProvider>
					<ContentSection type="secondary">
						<Media lessThan="xl">
							{/* iPad Pro */}
							<Parallax x={[-15, 0]}>
								<TitleText>
									<Typography
										variant="div"
										css={{
											fontSize: "180px",
											fontFamily: theme.typography.fontFamilies.headings,
											letterSpacing: "-0.06em",
										}}
									>
										{content.title1}{" "}
										<Typography
											font="inherit"
											color={theme.colors.primary.header}
										>
											{content.title2}
										</Typography>
									</Typography>
								</TitleText>
							</Parallax>
							<Parallax y={["200px", "10px"]}>
								<SubtitleText>
									<Typography
										variant="div"
										css={{
											fontSize: "180px",
											color: theme.colors.primary.header,
											fontFamily: theme.typography.fontFamilies.headings,
											letterSpacing: "-0.06em",
										}}
									>
										{content.years}
									</Typography>
									<Typography
										variant="h2"
										css={{
											paddingLeft: "20px",
											fontFamily: theme.typography.fontFamilies.headings,
											letterSpacing: "-0.04em",
											margin: "0",
											marginTop: "-75px",
										}}
										color={theme.colors.primary.gray}
									>
										{content.subTitle}
									</Typography>
								</SubtitleText>
							</Parallax>
						</Media>
						<Media greaterThan="lg">
							{/* Desktop */}
							<Parallax x={[-75, -15]}>
								<TitleText>
									<Typography
										variant="div"
										css={{
											fontSize: "210px",
											fontFamily: theme.typography.fontFamilies.headings,
											letterSpacing: "-0.06em",
										}}
									>
										{content.title1}{" "}
										<Typography
											font="inherit"
											color={theme.colors.primary.header}
										>
											{content.title2}
										</Typography>
									</Typography>
								</TitleText>
							</Parallax>
							<Parallax y={["200px", "0px"]}>
								<SubtitleText>
									<Typography
										variant="div"
										css={{
											fontSize: "210px",
											color: theme.colors.primary.header,
											fontFamily: theme.typography.fontFamilies.headings,
											letterSpacing: "-0.06em",
										}}
									>
										{content.years}
									</Typography>
									<Typography
										variant="h2"
										css={{
											paddingLeft: "20px",
											fontFamily: theme.typography.fontFamilies.headings,
											letterSpacing: "-0.04em",
											margin: "0",
											marginTop: "-75px",
										}}
										color={theme.colors.primary.gray}
									>
										{content.subTitle}
									</Typography>
								</SubtitleText>
							</Parallax>
						</Media>
						<Grid>
							<Row>
								<Column lg={5}>{""}</Column>
								<Column lg={7}>
									<img src={content.videoThumbnail} />
									<TextContainer theme={theme}>
										<SuperDescriptionTextContainer>
											<Typography variant="p" type="secondary" size="lg">
												{content.superDescription}
											</Typography>
										</SuperDescriptionTextContainer>
										<DescriptionTextContainer>
											<Typography variant="p" type="secondary" size="md">
												{content.description}
											</Typography>
										</DescriptionTextContainer>
										{content.actionUrl ? (
											<ButtonLink
												label={content.actionLabel}
												to={content.actionUrl}
												type="secondary"
											/>
										) : (
											<></>
										)}
									</TextContainer>
								</Column>
							</Row>
						</Grid>
						<Spacer />
					</ContentSection>
				</ParallaxProvider>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="primary" hPadding="0px" vPadding="40px">
					<SubtitleTextContainer theme={theme}>
						<Typography variant="h1">{content.years}</Typography>
					</SubtitleTextContainer>
					<SecondarySubtitleTextContainer>
						<Typography variant="p" size="md">
							{content.subTitle}
						</Typography>
					</SecondarySubtitleTextContainer>
					<img
						src={content.videoThumbnail}
						css={css`
							width: 100%;
							height: 200px;
						`}
					/>
					<Typography variant="p" size="xl" color={theme.colors.primary.gray}>
						{content.superDescription}
					</Typography>
					<Typography variant="p" size="md">
						{content.description}
					</Typography>
					{content.actionUrl ? (
						<>
							<MobileSpacer></MobileSpacer>
							<ButtonLink
								label={content.actionLabel}
								to={content.actionUrl}
								type="primary"
							/>
							<MobileSpacer></MobileSpacer>
						</>
					) : (
						<></>
					)}
				</ContentSection>
			</Media>
		</>
	);
};

export default OurHistory;
